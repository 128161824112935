import React from "react";
import RegisterHeader from "src/features/organization/components/RegisterHeader";
import ReactMarkdown from "react-markdown";
import privacyPolicy from "../content/privacy.md";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";

function PrivacyPolicy() {
  const [content, setContent] = React.useState("");

  React.useEffect(() => {
    fetch(privacyPolicy)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="flex flex-1 min-h-screen">
      <RegisterHeader />
      <div className="flex-1 px-20">
        <ReactMarkdown
          className="markdown-content"
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSlug]}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default React.memo(PrivacyPolicy);
