import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import thunk from "redux-thunk";
import { ThunkDispatch as ReduxThunkDispatch } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import discussionsReducer from "./features/discussions/slices/discussionsSlice";
import employeesReducer from "./features/employees/slices/employeesSlice";
import employeeLearningPathsReducer from "./features/learningPaths/slices/employeeLPSlice";
import groupsReducer from "./features/groups/slices/groupsSlice";
import learningPathBuilderReducer from "./features/learningPaths/slices/learningPathsBuilderSlice";
import modalReducer from "./features/modal/slices/modalSlice";
import navigationReducer from "./features/navMenu/slices/navMenuSlice";
import learningPathsPolicyFormReducer from "./features/learningPaths/slices/learningPathsPolicyFormSlice";
import searchReducer from "./features/search/slices/searchSlice";
import assessmentFormReducer from "./features/learningPaths/slices/assessmentFormSlice";
import aiQuestionGeneratorReducer from "./features/questions/slices/aIQuestionGeneratorSlice";
import questionFormReducer from "./features/questions/slices/questionFormSlice";
import videoFormReducer from "./features/learningPaths/slices/videoFormSlice";
import headerReducer from "./features/header/headerSlice";
import authReducer from "./features/auth/authSlice";
import scheduledTodosReducer from "./features/todos/todosSlice";
import templateAnnotationsReducer from "./features/e-signatures/slices/templateAnnotationsSlice";
import documentAnnotationsReducer from "./features/e-signatures/slices/documentAnnotationsSlice";
import documentAssignFormReducer from "./features/e-signatures/slices/documentAssignFormSlice";
import eSignatureFormReducer from "./features/e-signatures/slices/eSignatureFormSlice";
import documentViewerReducer from "./features/e-signatures/slices/documentViewerSlice";
import memoFormReducer from "./features/memos/slices/memoSlice";
import policyFormReducer from "./features/policies/slices/policySlice";
import RootState from "src/features/redux/types/rootState.types";
import tableGridReducer from "./features/grid/slices/tableGridSlice";
import videoReducer from "./features/videos/redux/videoSlice";

const rootReducer = combineReducers({
  aiQuestionGenerator: aiQuestionGeneratorReducer,
  assessmentForm: assessmentFormReducer,
  auth: authReducer,
  discussions: discussionsReducer,
  documentAssignForm: documentAssignFormReducer,
  documentAnnotations: documentAnnotationsReducer,
  employees: employeesReducer,
  employeeLearningPaths: employeeLearningPathsReducer,
  eSignatureForm: eSignatureFormReducer,
  groups: groupsReducer,
  header: headerReducer,
  learningPathsBuilder: learningPathBuilderReducer,
  learningPathsPolicyForm: learningPathsPolicyFormReducer,
  questionForm: questionFormReducer,
  modal: modalReducer,
  memoForm: memoFormReducer,
  navigation: navigationReducer,
  policyForm: policyFormReducer,
  search: searchReducer,
  scheduledTodos: scheduledTodosReducer,
  tableGrid: tableGridReducer,
  templateAnnotations: templateAnnotationsReducer,
  documentViewer: documentViewerReducer,
  videoForm: videoFormReducer,
  video: videoReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // only auth will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = ReduxThunkDispatch<RootState, undefined, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
