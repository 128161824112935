// Absolute routes
export const ROUTE_BASE = "/";
export const ROUTE_WILDCARD = "*";
export const ROUTE_API = "/api";
// Relative routes
export const PATH_ADMIN = "admin";
export const PATH_ASSESSMENTS = "assessments";
export const PATH_DEPARTMENTS = "departments";
export const PATH_DETAILS = "details";
export const PATH_DISCUSSIONS = "discussions";
export const PATH_DISCUSSION = "discussion";
export const PATH_EMPLOYEE = "employee";
export const PATH_EMPLOYEE_STATUS = "employee-status";
export const PATH_EMPLOYEES = "employees";
export const PATH_EMPLOYEE_LEARNING_PATHS = "employee-learning-paths";
export const PATH_EMPLOYEE_LP_STATS = "employee-lp-stats";
export const PATH_EMPLOYEE_LP_ACTIVITY = "employee-learning-path-activity";
export const PATH_LANDING = "landing";
export const PATH_LEARNING_PATHS = "learning-paths";
export const PATH_LINK_ASSESSMENT = "link-assessment";
export const PATH_LP_ASSESSMENTS = "learning-path-assessments";
export const PATH_LOGOUT = "logout";
export const PATH_MEMOS = "memos";
export const PATH_MODULES = "modules";
export const PATH_MODULE_CONTENTS = "module-contents";
export const PATH_POLICIES = "policies";
export const PATH_PASSWORD_RESET_REQUEST = "password-reset-request";
export const PATH_REGISTER = "register";
export const PATH_ROLES = "roles";
export const PATH_SELECT = "select";
export const PATH_SETTINGS = "settings";
export const PATH_SKIP_CHOICES = "skip-choices";
export const PATH_UPDATE = "update";
export const PATH_USERNAME_REQUEST = "username-request";
export const PATH_VIDEOS = "videos";
// Slugs
export const SLUG_EMPLOYEE_ID = ":employeeId";
export const SLUG_SELECTED_DEPARTMENT = ":selectedDepartment";
// Routes
export const ROUTE_ADMIN = `${ROUTE_BASE}${PATH_ADMIN}`;
export const ROUTE_DEPARTMENTS = `${ROUTE_BASE}${PATH_DEPARTMENTS}`;
export const ROUTE_DISCUSSIONS = `${ROUTE_BASE}${PATH_DISCUSSIONS}`;
export const ROUTE_DISCUSSION = `${ROUTE_BASE}${PATH_DISCUSSION}`;
export const ROUTE_EMPLOYEE = `${ROUTE_BASE}${PATH_EMPLOYEE}`;
export const ROUTE_EMPLOYEE_STATUS = `${ROUTE_BASE}${PATH_EMPLOYEE_STATUS}`;
export const ROUTE_EMPLOYEES = `${ROUTE_BASE}${PATH_EMPLOYEES}`;
export const ROUTE_EMPLOYEE_LEARNING_PATHS = `${ROUTE_BASE}${PATH_EMPLOYEE_LEARNING_PATHS}`;
export const ROUTE_LEARNING_PATHS = `${ROUTE_BASE}${PATH_LEARNING_PATHS}`;
export const ROUTE_LEARNING_PATH_REPORTS = `${ROUTE_BASE}${PATH_LEARNING_PATHS}/reports`;
export const ROUTE_LOGOUT = `${ROUTE_BASE}${PATH_LOGOUT}`;
export const ROUTE_MEMOS = `${ROUTE_BASE}${PATH_MEMOS}`;
export const ROUTE_POLICIES = `${ROUTE_BASE}${PATH_POLICIES}`;
export const ROUTE_REGISTER = `${ROUTE_BASE}${PATH_REGISTER}`;
export const ROUTE_ROLES = `${ROUTE_BASE}${PATH_ROLES}`;
export const ROUTE_UPDATE = `${ROUTE_BASE}${PATH_UPDATE}`;
export const ROUTE_VIDEOS = `${ROUTE_BASE}${PATH_VIDEOS}`;
// API Routes
export const ROUTE_API_ASSESSMENT_SELECT_LIST = `${ROUTE_API}/${PATH_ASSESSMENTS}/${PATH_SELECT}`;
export const ROUTE_API_EMPLOYEE = `${ROUTE_API}/${PATH_EMPLOYEE}`;
export const ROUTE_API_EMPLOYEE_STATUS = `${ROUTE_API}/${PATH_EMPLOYEE_STATUS}`;
export const ROUTE_API_EMPLOYEES = `${ROUTE_API}/${PATH_EMPLOYEES}`;
export const ROUTE_API_EMPLOYEE_LEARNING_PATHS = `${ROUTE_API}/${PATH_EMPLOYEE_LEARNING_PATHS}`;
export const ROUTE_API_EMPLOYEE_LP_STATS = `${ROUTE_API}/${PATH_EMPLOYEE_LP_STATS}`;
export const ROUTE_API_LEARNING_PATHS = `${ROUTE_API}/${PATH_LEARNING_PATHS}`;
export const ROUTE_API_LEARNING_PATH_ASSESSMENTS = `${ROUTE_API}/${PATH_LP_ASSESSMENTS}`;
export const ROUTE_API_MODULES = `${ROUTE_API}/${PATH_MODULES}`;
export const ROUTE_API_MODULE_CONTENTS = `${ROUTE_API}/${PATH_MODULE_CONTENTS}`;
