import { PaginatedApiResponse } from "../axios/axios.types";
import axiosClient from "../axios/client";
import { MULTIPART_FORM_HEADERS } from "../axios/headers";
import { SortParams } from "../invites/services/invitesService";
import { ROUTE_API_EMPLOYEE, ROUTE_API_EMPLOYEES } from "../site/routes";
import { UpdateEmployeeFormData } from "./hooks/useEmployeeImageMutation";
import { EmployeeFormSchemaType } from "./schemas";
import { AuthenticatedEmployee, EmployeeLogin } from "./types/employees.type";

export type ResetPasswordsResponse = {
  message: string;
  reset_employees: {
    reset_password_code: string;
    username: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  }[];
};

class EmployeeService {
  async bulkUploadEmployees(formData: FormData) {
    return await axiosClient
      .post("/api/employees/bulk-create/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }

  async getEmployee(id: number | string): Promise<AuthenticatedEmployee> {
    return await axiosClient
      .get(`/api/employees/${id}/`)
      .then((res) => res.data);
  }

  async getEmployees(): Promise<AuthenticatedEmployee[]> {
    return await axiosClient.get("/api/employees/").then((res) => res.data);
  }

  async getAllEmployeesPaginated({
    page = 1,
    ordering,
    search,
  }: SortParams): Promise<PaginatedApiResponse<AuthenticatedEmployee>> {
    return await axiosClient
      .get("/api/all-employees/", {
        params: {
          page,
          ordering,
          search,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeesPaginated({
    page = 1,
    ordering,
    search,
  }: SortParams): Promise<PaginatedApiResponse<AuthenticatedEmployee>> {
    return await axiosClient
      .get("/api/employees/", {
        params: {
          page,
          ordering,
          search,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeeLoginsPaginated(
    { employee_id, page = 1, sortBy, sortDirection, ...filters } = {} as any
  ): Promise<PaginatedApiResponse<EmployeeLogin>> {
    return await axiosClient.get("/api/employee-logins/", {
      params: {
        employee_id,
        page,
        sortBy,
        sortDirection,
        ...filters,
      },
    });
  }

  async getSupervisors(): Promise<AuthenticatedEmployee[]> {
    return await axiosClient.get("/api/supervisors/").then((res) => res.data);
  }

  async partialUpdateEmployee(employee: Partial<EmployeeFormSchemaType>) {
    return await axiosClient.patch(`/api/employees/${employee.id}/`, employee);
  }

  async resetPasswords(): Promise<ResetPasswordsResponse> {
    return await axiosClient.post("/api/employees/reset-passwords/");
  }

  // TODO: When updating an employee, shouldn't we just do updateEmployee and submit everything? or patch?
  async updateEmployeeImage({ id, formData }: UpdateEmployeeFormData) {
    return await axiosClient
      .patch(`${ROUTE_API_EMPLOYEE}/${id}/upload-image/`, formData, {
        headers: MULTIPART_FORM_HEADERS,
      })
      .then((res) => res.data);
  }

  async updateEmployeeStatus(id: number | string) {
    return await axiosClient
      .put(`/api/employee-status/${id}/`)
      .then((res) => res.data);
  }

  async updateUsername({
    id,
    username,
  }: {
    id: number | string;
    username: string;
  }) {
    return await axiosClient
      .patch(`${ROUTE_API_EMPLOYEES}/${id}/`, { username })
      .then((res) => res.data);
  }
}

const employeeService = new EmployeeService();

export default employeeService;
