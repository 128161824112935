import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import employeeService, { ResetPasswordsResponse } from "../employeeService";

const uploadSchema = z.object({
  file: z
    .any()
    .refine(
      (file) =>
        file?.[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        message: "Only Excel files are allowed.",
      }
    ),
});

type UploadFormSchema = z.infer<typeof uploadSchema>;

export default function EmployeeBulkCreateForm() {
  const [successData, setSuccessData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [resetSuccess, setResetSuccess] =
    useState<ResetPasswordsResponse | null>(null);
  const [resetError, setResetError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(uploadSchema),
  });

  const uploadMutation = useMutation({
    mutationFn: employeeService.bulkUploadEmployees,
    onSuccess: (data) => {
      setSuccessData(data);
      reset();
    },
    onError: (error: AxiosError) => {
      setErrorData(error?.response?.data || "An error occurred.");
    },
  });

  const resetPasswordsMutation = useMutation({
    mutationFn: employeeService.resetPasswords,
    onSuccess: (data) => {
      setResetSuccess(data);
    },
    onError: (error: AxiosError) => {
      setResetError(error?.response?.data || "An error occurred.");
    },
  });

  const onSubmit = (data: UploadFormSchema) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);
    uploadMutation.mutate(formData);
  };

  const handleResetPasswords = () => {
    resetPasswordsMutation.mutate();
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-xl font-bold mb-4">Bulk Upload Employees</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="file" className="block mb-2 font-medium">
            Upload Excel File
          </label>
          <input
            type="file"
            id="file"
            {...register("file")}
            className={`block w-full p-2 border rounded ${
              errors.file ? "border-red-500" : ""
            }`}
          />
          {errors.file && (
            <p className="mt-1 text-sm text-red-600">
              {errors?.file?.message?.toString()}
            </p>
          )}
        </div>
        <button
          type="submit"
          disabled={uploadMutation.isLoading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
        >
          {uploadMutation.isLoading ? "Uploading..." : "Upload"}
        </button>
      </form>

      {uploadMutation.isSuccess && (
        <div className="mt-4 p-4 border rounded bg-green-100">
          <h2 className="font-bold">Upload Successful</h2>
          <pre className="mt-2 text-sm">
            {JSON.stringify(successData, null, 2)}
          </pre>
        </div>
      )}

      {uploadMutation.isError && (
        <div className="mt-4 p-4 border rounded bg-red-100">
          <h2 className="font-bold text-red-600">Upload Failed</h2>
          <pre className="mt-2 text-sm">
            {JSON.stringify(errorData, null, 2)}
          </pre>
        </div>
      )}

      <div className="mt-6">
        <button
          onClick={handleResetPasswords}
          disabled={resetPasswordsMutation.isLoading}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:opacity-50"
        >
          {resetPasswordsMutation.isLoading
            ? "Resetting..."
            : "Reset Passwords"}
        </button>
      </div>

      {resetPasswordsMutation.isSuccess && (
        <div className="mt-4 p-4 border rounded bg-green-100">
          <h2 className="font-bold">Password Reset Successful</h2>
          <pre className="mt-2 text-sm">
            {JSON.stringify(resetSuccess, null, 2)}
          </pre>
        </div>
      )}

      {resetPasswordsMutation.isError && (
        <div className="mt-4 p-4 border rounded bg-red-100">
          <h2 className="font-bold text-red-600">Password Reset Failed</h2>
          <pre className="mt-2 text-sm">
            {JSON.stringify(resetError, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}
