import { createSlice } from "@reduxjs/toolkit";
import { WebSocketStatus } from "src/features/websockets/types/websockets.types";

export type DiscussionsState = {
  webSocketStatus: WebSocketStatus;
  status: "idle" | "loading" | "succeeded" | "failed";
  messageWithFileToLink: string | null;
};

const initialState: DiscussionsState = {
  webSocketStatus: WebSocketStatus.DISCONNECTED,
  status: "idle",
  messageWithFileToLink: null,
};

export const discussionsSlice = createSlice({
  name: "discussions",
  initialState,
  reducers: {
    addOrUpdateMessageWithFileToLink: (state, action) => {
      state.messageWithFileToLink = action.payload;
    },
    setWebSocketStatus: (state, action) => {
      state.webSocketStatus = action.payload;
    },
  },
});

export const { addOrUpdateMessageWithFileToLink, setWebSocketStatus } =
  discussionsSlice.actions;

export default discussionsSlice.reducer;
