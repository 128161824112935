import { Provider as AlertProvider } from "@blaumaus/react-alert";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import { pdfjs } from "react-pdf";
import LoadingAnimation from "./components/Animations/LoadingAnimation";
import { options } from "./features/alert/AlertTemplate";
import useAuth from "./features/auth/hooks/useAuth";
import hasGroupPermissions from "./features/auth/utils/hasGroupPermissions";
import ProtectedEmployeeBulkCreate from "./features/employees/routes/ProtectedEmployeeBulkCreate";
import { NON_EMPLOYEES_GROUP } from "./features/groups/groups";
import OnboardingWrapper from "./features/onboarding/routes/OnboardingWrapper";
import lazyRetry from "./features/utils/lazyRetry";
import "./index.css";
import { persistor, store } from "./store";
import PrivacyPolicyPage from "./features/legal/routes/PrivacyPolicyPage";
import CookiesPolicyPage from "./features/legal/routes/CookiesPolicyPage";
import TermsOfUsePage from "./features/legal/routes/TermsOfUsePage";
import EndUserLicensePage from "./features/legal/routes/EndUserLicensePage";

const AssessmentsListPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentsListPage"))
);
const SittingCompletedPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/SittingCompletedPage"))
);
const SittingTakeV2 = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/SittingTakeV2Page"))
);
const DocumentApprovalForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/e-signatures/components/Documents/DocumentApprovalForm"
      )
  )
);
const DocumentSignRoute = lazy(() =>
  lazyRetry(() => import("./features/e-signatures/routes/DocumentSignRoute"))
);
const ESignaturesDashboard = lazy(() =>
  lazyRetry(() => import("./features/e-signatures/routes/ESignaturesDashboard"))
);
const ProtectedESignaturesWithSidebarRoute = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/e-signatures/routes/ProtectedESignaturesWithSidebarRoute"
      )
  )
);
const UpdateEmployeeTypesForm = lazy(() =>
  lazyRetry(
    () => import("./features/employeeTypes/components/UpdateEmployeeTypesForm")
  )
);
const LearningPathsTakeRoute = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/LearningPathsTakeRoute")
  )
);
const LearningPathsListPage = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routesV2/LearningPathsListPage")
  )
);
const LearningPathsTakeRouteV2 = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routesV2/LearningPathsTakeRouteV2")
  )
);
const MemoCreateEditPage = lazy(() =>
  lazyRetry(
    () => import("./features/memos/components/Steps/MemoCreateEditPage")
  )
);
const OnboardingAdminAccount = lazy(() =>
  lazyRetry(() => import("./features/onboarding/routes/OnboardingAdminAccount"))
);
const OnboardingDepartments = lazy(() =>
  lazyRetry(() => import("./features/onboarding/routes/OnboardingDepartments"))
);
const OnboardingEmployeeTypes = lazy(() =>
  lazyRetry(
    () => import("./features/onboarding/routes/OnboardingEmployeeTypes")
  )
);
const PolicyCreateEditPage = lazy(() =>
  lazyRetry(
    () => import("./features/policies/components/Steps/PolicyCreateEditPage")
  )
);
const EmployeeWeeklySchedule = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/components/EmployeeWeeklySchedule")
  )
);
const LaborBudgetWeeklyPage = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/components/LaborBudgetWeeklyPage")
  )
);
const SchedulesSupervisorDashboard = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/components/SchedulesSupervisorDashboard")
  )
);
const ShiftConflicts = lazy(() =>
  lazyRetry(() => import("./features/schedules/components/ShiftConflicts"))
);
const ShiftTransferDetailPage = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/components/ShiftTransferDetailPage")
  )
);
const ShiftTransfers = lazy(() =>
  lazyRetry(() => import("./features/schedules/components/ShiftTransfers"))
);
const TimeOffRequestDetailPage = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/components/TimeOffRequestDetailPage")
  )
);
const TimeOffRequests = lazy(() =>
  lazyRetry(() => import("./features/schedules/components/TimeOffRequests"))
);
const ProtectedSchedulesRoute = lazy(() =>
  lazyRetry(() => import("./features/schedules/routes/ProtectedSchedulesRoute"))
);
const ProtectedSupervisorScheduleCalendar = lazy(() =>
  lazyRetry(
    () =>
      import("./features/schedules/routes/ProtectedSupervisorScheduleCalendar")
  )
);
const ScheduleRolesSyncRoute = lazy(() =>
  lazyRetry(() => import("./features/schedules/routes/ScheduleRolesSyncRoute"))
);
const VerifyEmployeeHourlyRates = lazy(() =>
  lazyRetry(
    () => import("./features/schedules/routes/VerifyEmployeeHourlyRates")
  )
);
const VerifyScheduleRoles = lazy(() =>
  lazyRetry(() => import("./features/schedules/routes/VerifyScheduleRoles"))
);
const VideoCreateEditPage = lazy(() =>
  lazyRetry(() => import("./features/videos/routes/VideoCreateEditPage"))
);
const LearningPathBuilder = lazy(() =>
  lazyRetry(() => import("./features/learningPaths/routes/LearningPathBuilder"))
);
const LPReportsPage = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/components/LPReports/LPReportsPage")
  )
);
const LPReportTable = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/components/LPReports/LPReportTable")
  )
);
const LoginPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/LoginPage"))
);
const PasswordResetRequestPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/PasswordResetRequestPage"))
);
const ResetPasswordPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/ResetPasswordPage"))
);
const UsernameRequestPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/UsernameRequestPage"))
);
const BillingSetupFlow = lazy(() =>
  lazyRetry(() => import("./features/settings/BillingSetupFlow"))
);
const BillingDetailsPage = lazy(() =>
  lazyRetry(() => import("./features/settings/BillingDetailsPage"))
);
const SubscriptionAndTerms = lazy(() =>
  lazyRetry(() => import("./features/settings/SubscriptionAndTerms"))
);
const PaymentMethod = lazy(() =>
  lazyRetry(() => import("./features/settings/PaymentMethod"))
);
const PoliciesStatsPage = lazy(() =>
  lazyRetry(() => import("./features/policies/components/PoliciesStatsPage"))
);
const ProtectedAssessmentsRoute = lazy(() =>
  lazyRetry(
    () => import("./features/assessments/routes/ProtectedAssessmentsRoute")
  )
);
const DocumentDetailRoute = lazy(() =>
  lazyRetry(() => import("./features/e-signatures/routes/DocumentDetailRoute"))
);
const LearningPathDetailPage = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/LearningPathDetailPage")
  )
);
const EmployeeLearningPathDetailPage = lazy(() =>
  lazyRetry(
    () =>
      import("./features/learningPaths/routes/EmployeeLearningPathDetailPage")
  )
);
const AssessmentCreateEditPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentForm"))
);
const AssessmentDetailPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentDetailPage"))
);
const EmployeeSittingRoute = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/EmployeeSittingRoute"))
);
const ProtectedESignaturesRoute = lazy(() =>
  lazyRetry(
    () => import("./features/e-signatures/routes/ProtectedESignaturesRoute")
  )
);
const TemplateForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/e-signatures/components/TemplateComponents/TemplateForm"
      )
  )
);
const DocumentAssignForm = lazy(() =>
  lazyRetry(
    () =>
      import("./features/e-signatures/components/Documents/DocumentAssignForm")
  )
);
const LogoutPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/LogoutPage"))
);
const OrganizationForm = lazy(() =>
  lazyRetry(() => import("./components/Forms/OrganizationForm"))
);
const LandingPage = lazy(() =>
  lazyRetry(() => import("./features/landing/routes/LandingPage"))
);
const ProtectedDashboard = lazy(() =>
  lazyRetry(() => import("./features/dashboard/routes/ProtectedDashboardPage"))
);
const ProtectedDiscussions = lazy(() =>
  lazyRetry(() => import("./features/discussions/routes/ProtectedDiscussions"))
);
const Discussion = lazy(() =>
  lazyRetry(() => import("./features/discussions/components/Discussion"))
);
const ProtectedOrganization = lazy(() =>
  lazyRetry(
    () => import("./features/organization/routes/ProtectedOrganization")
  )
);
const ProtectedUpdateOrganization = lazy(() =>
  lazyRetry(
    () => import("./features/organization/routes/ProtectedUpdateOrganization")
  )
);
const ProtectedDepartments = lazy(() =>
  lazyRetry(() => import("./features/departments/routes/ProtectedDepartments"))
);
const ProtectedDepartmentsUpdate = lazy(() =>
  lazyRetry(
    () => import("./features/departments/routes/ProtectedDepartmentsUpdate")
  )
);
const ProtectedRoles = lazy(() =>
  lazyRetry(() => import("./features/employeeTypes/routes/ProtectedRoles"))
);
const ProtectedEmployeesRoute = lazy(() =>
  lazyRetry(() => import("./features/employees/routes/ProtectedEmployeesRoute"))
);
const ProtectedEmployeeDetail = lazy(() =>
  lazyRetry(() => import("./features/employees/routes/ProtectedEmployeeDetail"))
);
const ProtectedMemosRoute = lazy(() =>
  lazyRetry(() => import("./features/memos/routes/ProtectedMemosRoute"))
);
const MemoStatsPage = lazy(() =>
  lazyRetry(() => import("./features/memos/components/MemoStatsPage"))
);
const MemoDetailPage = lazy(() =>
  lazyRetry(() => import("./features/memos/components/MemoDetailPage"))
);
const ProtectedVideosRoute = lazy(() =>
  lazyRetry(() => import("./features/videos/routes/ProtectedVideosRoute"))
);
const SettingsPage = lazy(() =>
  lazyRetry(() => import("./features/settings/routes/ProtectedSettingsRoute"))
);
const VideosListPage = lazy(() =>
  lazyRetry(() => import("./features/videos/routes/VideosListPage"))
);
const VideosDetailPage = lazy(() =>
  lazyRetry(() => import("./features/videos/components/VideosDetailPage"))
);
const VideosStatsPage = lazy(() =>
  lazyRetry(() => import("./features/videos/components/VideosStatsPage"))
);
const NotFound = lazy(() =>
  lazyRetry(() => import("./features/errors/routes/NotFound"))
);

const ProtectedLearningPaths = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/ProtectedLearningPaths")
  )
);

const EmployeeLPMultipleReportTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/EmployeeLPMultipleReportTable"
      )
  )
);

const EmployeeLPSingleReportTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/EmployeeLPSingleReportTable"
      )
  )
);

const LPEmployeeQuarterlyReports = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/LPEmployeeQuarterlyReports"
      )
  )
);

const ProtectedPoliciesRoute = lazy(() =>
  lazyRetry(() => import("./features/policies/routes/ProtectedPoliciesRoute"))
);

const PoliciesDetailPage = lazy(() =>
  lazyRetry(() => import("./features/policies/components/PoliciesDetailPage"))
);

Sentry.init({
  dsn: "https://682e0f1de6554c62a2734b02e5cf94fe@o1380729.ingest.sentry.io/4503903422119936",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5, // Set to lower value as errors occur less frequently e.g. 0.2 - 20%
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AppRoutes = () => {
  const { employee } = useAuth();
  return (
    <BrowserRouter basename={"/"}>
      <Suspense
        fallback={<LoadingAnimation containerClassName={"h-screen centered"} />}
      >
        <Routes>
          <Route path={"privacy-policy"} element={<PrivacyPolicyPage />} />
          <Route path={"terms-of-use"} element={<TermsOfUsePage />} />
          <Route path={"cookies-policy"} element={<CookiesPolicyPage />} />
          <Route path={"end-user-license"} element={<EndUserLicensePage />} />
          <Route path={"login"} element={<LoginPage />} />
          <Route path={"logout"} element={<LogoutPage />} />
          <Route path={"username-request"} element={<UsernameRequestPage />} />
          <Route
            path={"reset-password-request"}
            element={<PasswordResetRequestPage />}
          />
          <Route path={"reset-password"} element={<ResetPasswordPage />} />
          <Route path={"register"} element={<OnboardingWrapper />}>
            <Route path={"organization"} element={<OrganizationForm />} />
            <Route path={"roles"} element={<OnboardingEmployeeTypes />} />
            <Route path={"departments"} element={<OnboardingDepartments />} />
            <Route path={"admin"} element={<OnboardingAdminAccount />} />
          </Route>
          <Route
            path={"register"}
            element={<OnboardingWrapper totalSteps={3} />}
          >
            <Route path={"schedules"} element={<ScheduleRolesSyncRoute />} />
            <Route
              path={"schedules/roles/verify"}
              element={<VerifyScheduleRoles />}
            />
            <Route
              path={"schedules/employees/verify"}
              element={<VerifyEmployeeHourlyRates />}
            />
          </Route>
          <Route
            path={"employees/bulk-create"}
            element={<ProtectedEmployeeBulkCreate />}
          />
          <Route path={"billing"} element={<BillingSetupFlow />}>
            <Route path={"subscription"} element={<SubscriptionAndTerms />} />
            <Route path={"details"} element={<BillingDetailsPage />} />
            <Route path={"payment-method"} element={<PaymentMethod />} />
          </Route>
          <Route path={""} element={<LandingPage />} />
          <Route path={"dashboard"} element={<ProtectedDashboard />} />
          <Route path={"schedules"} element={<ProtectedSchedulesRoute />}>
            <Route
              index
              element={
                hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                  <SchedulesSupervisorDashboard />
                ) : (
                  <EmployeeWeeklySchedule />
                )
              }
            />
            <Route path="me" element={<EmployeeWeeklySchedule />} />
            <Route
              path={"departments/:id"}
              element={<ProtectedSupervisorScheduleCalendar />}
            />
            <Route path={"time-off-requests"} element={<TimeOffRequests />} />
            <Route
              path={"time-off-requests/:id"}
              element={<TimeOffRequestDetailPage />}
            />
            <Route path={"shift-transfers"} element={<ShiftTransfers />} />
            <Route
              path="shift-transfers/:id"
              element={<ShiftTransferDetailPage />}
            />
            <Route path={"shift-conflicts"} element={<ShiftConflicts />} />
            <Route path={"labor-budget"} element={<LaborBudgetWeeklyPage />} />
          </Route>
          <Route path={"discussions"} element={<ProtectedDiscussions />}>
            <Route index element={<Discussion />} />
            <Route path={":uuid"} element={<Discussion />} />
          </Route>
          <Route path={"memos"} element={<ProtectedMemosRoute />}>
            <Route
              index
              element={
                hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                  <MemoStatsPage />
                ) : (
                  <MemoDetailPage />
                )
              }
            />
            <Route path={":id"} element={<MemoDetailPage />} />
            <Route path={"form"} element={<MemoCreateEditPage />} />
            <Route path={"form/:id"} element={<MemoCreateEditPage />} />
          </Route>
          <Route path={"organization"} element={<ProtectedOrganization />}>
            <Route path={"update"} element={<ProtectedUpdateOrganization />} />
          </Route>
          <Route path={"departments"} element={<ProtectedDepartments />}>
            <Route path={"update"} element={<ProtectedDepartmentsUpdate />} />
          </Route>
          <Route path={"roles"} element={<ProtectedRoles />}>
            <Route path={"update"} element={<UpdateEmployeeTypesForm />} />
          </Route>
          <Route path={"employees"} element={<ProtectedEmployeesRoute />}>
            <Route path={":id"} element={<ProtectedEmployeeDetail />} />
          </Route>
          <Route path={"assessments"} element={<ProtectedAssessmentsRoute />}>
            <Route index element={<AssessmentsListPage />} />
            <Route path={":id"} element={<AssessmentDetailPage />} />
            <Route path={"form"} element={<AssessmentCreateEditPage />} />
            <Route path={`form/:id`} element={<AssessmentCreateEditPage />} />
            <Route path={"sittings/:id/take"} element={<SittingTakeV2 />} />
            <Route
              path={"sittings/:id/result"}
              element={<SittingCompletedPage />}
            />
            <Route path={`marking/:id`} element={<EmployeeSittingRoute />} />
          </Route>
          <Route path={"learning-paths"} element={<ProtectedLearningPaths />}>
            <Route index element={<LearningPathsListPage />} />
            <Route path={":id"} element={<LearningPathDetailPage />} />
            <Route path={"form"} element={<LearningPathBuilder />} />
            <Route path={`form/:id/`} element={<LearningPathBuilder />} />
            <Route
              path={`employee-path/:id/`}
              element={<EmployeeLearningPathDetailPage />}
            />
          </Route>
          <Route
            path={"learning-paths/:id/take"}
            element={<LearningPathsTakeRoute />}
          />{" "}
          <Route
            path={"learning-paths/:id/take/v2"}
            element={<LearningPathsTakeRouteV2 />}
          />
          <Route path={"learning-paths/reports"} element={<LPReportsPage />}>
            <Route index element={<LPReportTable />} />
            <Route path={":id"} element={<LPEmployeeQuarterlyReports />} />
            <Route path={`path/:id`} element={<LPReportTable />} />
            <Route
              path={":id/single/:pathId/"}
              element={<EmployeeLPSingleReportTable />}
            />
            <Route
              path={":id/multiple/"}
              element={<EmployeeLPMultipleReportTable />}
            />
          </Route>
          <Route path={"policies"} element={<ProtectedPoliciesRoute />}>
            <Route
              index
              element={
                hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                  <PoliciesStatsPage />
                ) : (
                  <PoliciesDetailPage />
                )
              }
            />
            <Route path={":id"} element={<PoliciesDetailPage />} />
            <Route path={"form"} element={<PolicyCreateEditPage />} />
            <Route path={`form/:id`} element={<PolicyCreateEditPage />} />
          </Route>
          <Route path={"e-signatures"} element={<ProtectedESignaturesRoute />}>
            <Route index element={<ESignaturesDashboard />} />
          </Route>
          <Route
            path={"e-signatures"}
            element={<ProtectedESignaturesWithSidebarRoute />}
          >
            <Route path={"templates/create"} element={<TemplateForm />} />
            <Route path={`templates/:id/edit`} element={<TemplateForm />} />
            <Route path={`documents/:id`} element={<DocumentDetailRoute />} />
            <Route path="documents/:id/sign" element={<DocumentSignRoute />} />
            <Route
              path={"documents/:id/approve"}
              element={<DocumentApprovalForm />}
            />
            <Route
              path={`documents/:id/assign`}
              element={<DocumentAssignForm />}
            />
          </Route>
          <Route path={"videos"} element={<ProtectedVideosRoute />}>
            <Route index element={<VideosListPage />} />
            <Route path={":id"} element={<VideosDetailPage />} />
            <Route path={"form"} element={<VideoCreateEditPage />} />
            <Route path={"form/:id"} element={<VideoCreateEditPage />} />
            <Route path={"stats"} element={<VideosStatsPage />} />
          </Route>
          <Route path={"settings"} element={<SettingsPage />} />
          <Route path={"*"} element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const stripePublishableKey = process.env.REACT_APP_STRIPE_API_KEY;

if (!stripePublishableKey) {
  console.log("node env", process.env.NODE_ENV);
  console.log("stripe key", stripePublishableKey);
  console.log("stripe key", process.env.REACT_APP_STRIPE_API_KEY);
  throw new Error("Stripe publishable key not found");
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const stripePromise = loadStripe(stripePublishableKey);
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <AlertProvider {...options}>
      <PersistGate loading={<LoadingAnimation />} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor="#131316"
            highlightColor="#232227"
            borderRadius={5}
          >
            <Elements stripe={stripePromise}>
              <AppRoutes />
            </Elements>
          </SkeletonTheme>
        </QueryClientProvider>
      </PersistGate>
    </AlertProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
